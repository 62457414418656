import { useState, useEffect, useContext } from "react";
import { motion } from "framer-motion";
import styles from "../Fejllister/MainContent/MainContent.module.scss";
import { useHistory } from "react-router-dom";
import opgaveApi from "api/opgave";
import { OpgaveBrugerStatus } from "interfaces/Opgaveliste/enums";
import { AuthContext } from "context/AuthProvider";
import IngenOpgaver from "../Fejllister/MainContent/IngenOpgaver";
import { RealTimeContext } from "context/RealTimeProvider";
import { usePrevious } from "hooks/usePrevious";
import SublistItem from "./SublistItem";
import { TaskQueryContext } from "context/TaskQueryProvider";
import Filter from "./MainContent/Filter";
import TaskNextPreviousPage from "components/common/NextPreviousPage/TaskNextPreviousPage";
import { OpgaveListePageOptions } from "interfaces/paging";
import { History } from "history";
// import FilterOptions from "./MainContent/Header/FilterOptions";

interface ListProps {
  opgaveliste: OpgaveListe;
}

export default function Sublist(props: ListProps) {
  const { opgaveliste } = props;
  const { opgaveMessages } = useContext(RealTimeContext);
  const { currentPage, loading, options, taskList } =
    useContext(TaskQueryContext);
  const prevOpgaveMessages = usePrevious(opgaveMessages);

  const [opgaverState, setOpgaverState] = useState<{
    state: "IDLE" | "LOADING" | "ERROR";
    data: Array<Opgave>;
    totaltAntal: number;
  }>({ state: "IDLE", data: [], totaltAntal: 0 });

  useEffect(() => {
    if (
      currentPage?.length &&
      opgaveMessages.length &&
      (!prevOpgaveMessages || prevOpgaveMessages.length < opgaveMessages.length)
    ) {
      const newOpgaveMessage = opgaveMessages[opgaveMessages.length - 1];
      if (opgaverState.data.find((o) => o.id === newOpgaveMessage.id)) {
        setOpgaverState({
          ...opgaverState,
          data: opgaverState.data.map((o) => {
            if (o.id === newOpgaveMessage.id) {
              return { ...o, ...newOpgaveMessage };
            }
            return o;
          }),
        });
      }
    }
  }, [
    opgaveMessages,
    prevOpgaveMessages,
    opgaverState,
    setOpgaverState,
    currentPage,
  ]);

  const history = useHistory();

  const authState = useContext(AuthContext);

  const setOpgaveBrugerStatus = async (
    opgaveId: string,
    brugerStatus: OpgaveBrugerStatus
  ) => {
    try {
      let opdateretOpgave = await opgaveApi.putOpgaveBrugerStatus(
        opgaveId,
        brugerStatus,
        authState
      );

      if (opdateretOpgave && opgaverState.state === "IDLE") {
        const index = opgaverState.data.findIndex((o) => o.id === opgaveId);
        setOpgaverState({
          ...opgaverState,
          data: [
            ...opgaverState.data.slice(0, index),
            // #242 - adresser kommer ikke med i put-response for opgave
            { ...opdateretOpgave, adresse: opgaverState.data[index].adresse },
            ...opgaverState.data.slice(index + 1),
          ],
        });
      }
      return opdateretOpgave;
    } catch (error) {
      if (error === "CANCEL") {
        // The request was canceled, all is well
        console.debug(error);
      } else {
        return undefined;
      }
    }
  };

  const renderLoading = () => {
    return Array.from(new Array(10)).map((o, i) => {
      return (
        <motion.li key={i} className={`${styles.loading}`}>
          <div className={styles.text}>
            <div
              style={{ width: 100 + "%", height: 80 }}
              className={styles.line}
            ></div>
          </div>
        </motion.li>
      );
    });
  };
  const renderError = () => {
    return (
      <motion.div layout transition={{ damping: 300 }}>
        <h3>En uventet fejl opstod</h3>
      </motion.div>
    );
  };

  const renderOpgaver = (
    opgaveliste: OpgaveListe | undefined,
    history: History<any>,
    opgaveListePageOptions: OpgaveListePageOptions
  ) => {
    if (!opgaveliste || loading) return null;
    let data = currentPage ? currentPage : [];
    if (data.length === 0) return <IngenOpgaver />;
    return data.map((o) => {
      return (
        <motion.div layout transition={{ damping: 300 }} key={o.id}>
          <SublistItem
            opgave={o}
            setOpgaveBrugerStatus={setOpgaveBrugerStatus}
            listetype={opgaveliste.listetype}
            goToEjendom={() => {
              history.push(
                `/ejendom?bfe=${o.bfe}&opgaveid=${o.id}&sortorder=${opgaveListePageOptions.sortOrder}&kommunenr=${opgaveListePageOptions.kommunenr}`
              );
            }}
            brugerStatusUI={opgaveliste.brugerstatus}
          />
        </motion.div>
      );
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className=""
      id="opgaveliste-main-content"
    >
      <Filter data={opgaveliste} />
      <motion.ul
        className="list-group w-100 mw-100 border-top border-1 container-fluid p-0"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        {opgaverState.state === "IDLE" &&
          taskList &&
          renderOpgaver(taskList, history, options)}
        {opgaverState.state === "IDLE" && (
          <div className="w-100 d-flex justify-content-center my-3">
            <TaskNextPreviousPage />
          </div>
        )}
        {loading && renderLoading()}
        {opgaverState.state === "ERROR" && renderError()}
      </motion.ul>
    </motion.div>
  );
}

// const renderNewOpgaver = (
//   opgaverState: {
//     state: "IDLE" | "LOADING" | "ERROR";
//     data: Array<Opgave>;
//     totaltAntal: number;
//   },
//   opgaveliste: OpgaveListe | undefined,
//   history,
//   sortOrder
// ) => {
//   if (!opgaveliste) return null;
//   let data = opgaverState.data;
//   if (data.length === 0) return <IngenOpgaver />;
//   let filteredData = data.filter(
//     (item) => brugerStatus === item.brugerStatus
//   );
//   return filteredData.map((o) => {
//     return (
//       <motion.div layout transition={{ damping: 300 }} key={o.id}>
//         <SublistItem
//           opgave={o}
//           setOpgaveBrugerStatus={setOpgaveBrugerStatus}
//           listetype={opgaveliste.listetype}
//           goToEjendom={() => {
//             history.push(
//               `/ejendom?bfe=${o.bfe}&opgaveid=${o.id}&listeid=${opgaveliste.id}&sortorder=${sortOrder}`
//             );
//           }}
//           brugerStatusUI={opgaveliste.brugerstatus}
//           opgaveliste={opgaveliste}
//         />
//       </motion.div>
//     );
//   });
// };
