import { get, getResponse, put, validateAuth } from "../index";
import {
  OpgaveSortOrder,
  OpgaveBrugerStatus,
  OpgaveKommentarAdgang,
} from "../../interfaces/Opgaveliste/enums";
import { AuthState } from "context/AuthProvider";
import { AxiosResponse } from "axios";

const getAll = async (authState: AuthState) => {
  if (validateAuth(authState, "fejllister")) {
    try {
      return await get<Opgave[]>({
        query: "opgave?page=1&pageSize=10",
      });
    } catch (error) {
      return Promise.reject(error);
    }
  } else {
    return Promise.reject("FORBIDDEN");
  }
};

const getBfe = async (bfe: number, authState: AuthState) => {
  if (validateAuth(authState, "fejllister")) {
    try {
      return await get<Opgave[]>({
        query: `opgave?page=1&pageSize=1000&bfe=${bfe}`, // Opgaver på ejendom skal ikke begrænses af paging
      });
    } catch (error) {
      return Promise.reject(error);
    }
  } else {
    return Promise.reject("FORBIDDEN");
  }
};

const getFromListe = async (
  id: string,
  authState: AuthState,
  options?: {
    page?: number;
    pageSize?: number;
    sortOrder?: OpgaveSortOrder;
    brugerId?: string;
    brugerStatus?: string;
    kommunenr?: string;
  }
) => {
  if (validateAuth(authState, "fejllister")) {
    try {
      if (options) {
        const {
          page = 1,
          pageSize = 100,
          sortOrder = OpgaveSortOrder.opgaveIdAsc,
          brugerId,
          brugerStatus,
          kommunenr,
        } = options;
        const params = new URLSearchParams();
        params.append("listeId", id);
        params.append("page", page.toString());
        params.append("pageSize", pageSize.toString());
        params.append("sortOrder", sortOrder.toString());
        if (brugerId) params.append("brugerId", brugerId);
        if (brugerStatus) params.append("brugerStatus", brugerStatus);
        if (kommunenr) params.append("kommunenr", kommunenr);
        return await getResponse<Opgave[]>({
          query: `opgave?${params.toString()}`,
        });
      } else {
        return await getResponse<Opgave[]>({
          query: `opgave?page=1&pageSize=100&listeId=${id}`,
        });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  } else {
    return Promise.reject("FORBIDDEN");
  }
};

const getId = async (id: string, authState: AuthState) => {
  if (validateAuth(authState, "fejllister")) {
    try {
      return await get<Opgave>({
        query: `opgave/${id}`,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  } else {
    return Promise.reject("FORBIDDEN");
  }
};

const getOpgaveOrder = async (
  id: string,
  sortorder: OpgaveSortOrder,
  kommunenr: string,
  authState: AuthState
) => {
  if (validateAuth(authState, "fejllister")) {
    try {
      return await get<OpgaveOrder>({
        query: `opgave/${id}/opgaveorder?sortOrder=${sortorder}&kommunenr=${kommunenr}`,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  } else {
    return Promise.reject("FORBIDDEN");
  }
};

const putOpgaveBrugerStatus = async (
  id: string,
  brugerStatus: OpgaveBrugerStatus,
  authState
) => {
  if (validateAuth(authState, "fejllister")) {
    try {
      return await put({
        query: `opgave/${id}`,
        body: {
          brugerStatus: brugerStatus,
        },
      });
    } catch (error) {
      return Promise.reject(error);
    }
  } else {
    return Promise.reject("FORBIDDEN");
  }
};

const putKommentarText = async (
  id: string,
  adgang: OpgaveKommentarAdgang,
  kommentar: Opgave["kommentar"],
  authState
) => {
  if (
    validateAuth(authState, "fejllister") &&
    adgang === OpgaveKommentarAdgang.skriveAdgang
  ) {
    // Det kræver fejlliste adgang og skriveadgang at rette en kommentar på en opgave
    try {
      return await put({
        query: `opgave/${id}`,
        body: {
          kommentar: kommentar,
        },
      });
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  } else {
    return Promise.reject("FORBIDDEN");
  }
};

const api: OpgaveApi = {
  getAll,
  getBfe,
  getFromListe,
  getId,
  getOpgaveOrder,
  putOpgaveBrugerStatus,
  putKommentarText,
};

export default api;

export interface OpgaveApi {
  getAll: {
    (authState: AuthState): Promise<Array<Opgave>>;
  };
  getBfe: {
    (bfe: number, authState: AuthState): Promise<Array<Opgave>>;
  };
  getFromListe: {
    (
      id: string,
      authState: AuthState,
      options?: {
        page?: number;
        pageSize?: number;
        brugerId?: string;
        brugerStatus?: string;
        kommunenr?: string;
        sortOrder?: OpgaveSortOrder;
      }
    ): Promise<AxiosResponse<Opgave[]>>;
  };
  getId: {
    (id: string, authState: AuthState): Promise<Opgave>;
  };
  getOpgaveOrder: {
    (
      id: string,
      sortorder: OpgaveSortOrder,
      kommunenr: string,
      authState: AuthState
    ): Promise<OpgaveOrder>;
  };
  putOpgaveBrugerStatus: {
    (id: string, brugerStatus: OpgaveBrugerStatus, authState: AuthState);
  };
  putKommentarText: {
    (
      id: string,
      adgang: OpgaveKommentarAdgang,
      kommentar: Opgave["kommentar"],
      authState: AuthState
    );
  };
}
